import { setRegions, setRegionsByCountry, setRegionsLoading } from '../../actions'
import { getRegionsByCountry } from '../../selectors'
import { RegionsApi } from '../../api'

export const fetchRegions = (countryId) => {
  return async (dispatch, getState) => {
    if (!countryId) {
      dispatch(setRegions([]))
      dispatch(setRegionsByCountry(countryId, []))
    } else {
      dispatch(setRegionsLoading(true))
      const state = getState()
      const regions = getRegionsByCountry(state, countryId)
      if (!regions || (regions && !regions.length)) {
        const api = new RegionsApi()
        const result = await api.getRegionsByCountry(countryId)
        dispatch(setRegions(result))
        dispatch(setRegionsByCountry(countryId, result))
      } else {
        dispatch(setRegions(regions))
        dispatch(setRegionsByCountry(countryId, regions))
      }
      dispatch(setRegionsLoading(false))
    }
  }
}
