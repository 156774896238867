import React from 'react'
import { connect } from 'react-redux'
import { Notification } from 'react-notification'
import { AdsList } from '../src/modules/ads/list/components'
import { PanelMobile } from '../src/modules/ads/list/components/PanelMobile'
import { FreeAdsHomePagination, AllAdsHomePagination } from '../src/modules/ads/pagination/components'
import { AdsHeaderExtended } from '../src/modules/ads/filter/Container'
import { setCategoriesAndGroups } from 'SRC/modules/categories/actions'
import { getUserPackageInactiveMsg } from '../src/modules/users/Profile/selectors'
import { setUserPackageInactiveMsg } from '../src/modules/users/Profile/actions'
import { adsTypes, globalOptions } from '../src/core/constants'
import { fetchAds } from '../src/modules/ads/list/actions'
import { getPaginationParams } from '../src/modules/ads/pagination/selectors'
import { Page, Page404 } from '../src/ui'
import PropTypes from 'prop-types'
import initialState from '../src/modules/redux/initialState'
import { deepCopy } from '../src/utils'
import { fetchCountries } from 'SRC/modules/geoObjects/countries/actions'
import { fetchRegions } from 'SRC/modules/geoObjects/regions/actions'
import { fetchCitiesByCountryWithinRegions } from '../src/modules/geoObjects/cities/actions'
import { CategoryHorizontalMenuMobile } from '../src/modules/categories/components'
import config from '../src/config/config.yaml'

class IndexPage extends React.Component {
  static async getInitialProps ({store, query, req, isServer}) {
    const status = 'Published'
    const sortBy = 'dateDesc'
    const page = query.page ? Number(query.page) : 1
    const type = query.type || adsTypes.all

    const isMobileDevice = store.getState().common.isMobileDevice

    const limit = isMobileDevice
      ? globalOptions.adsCountPerPageWithoutSidebarMobile
      : globalOptions.adsCountPerPageWithoutSidebar

    const isPageExists = !isNaN(page) && (type === adsTypes.all || type === adsTypes.free || type === adsTypes.paid)

    if (isPageExists) {
      const requestParams = query
        ? { ...query, page, type, limit, status, sortBy }
        : { page, type, limit, status, sortBy }

      const paginationParams = {
        ...store.getState().ads.pagination.params,
        currentPage: Number(page),
        adsType: type,
        countPerPage: limit
      }

      /**
       * Fetch countries and cities
       */
      if (!isMobileDevice) {
        await Promise.all([
          store.dispatch(fetchCitiesByCountryWithinRegions(globalOptions.MontenegroId)),
          store.dispatch(fetchRegions(globalOptions.MontenegroId))
        ])
      }

      await store.dispatch(fetchAds(requestParams, paginationParams))
    }

    await store.dispatch(setCategoriesAndGroups())

    const isInfiniteScrollEnabled = store.getState().ads.list.infiniteScrollEnabled

    return {
      title: isPageExists
        ? `${config.pages.index.title} | ${config.pages.defaultTitle}`
        : config.pages.notFoundTitle,
      isPageExists,
      layoutStyle: config.noSidebarLayout ? 'no-sidebar' : 'default',
      isMobileDevice,
      type,
      noAdsMessage: config.pages.index.noPaidAds,
      isInfiniteScrollEnabled,
      state: IndexPage.getPageState(store, isPageExists)
    }
  }

  static getPageState (store, isPageExists = false) {
    const state = deepCopy(initialState)
    if (store) {
      const currentState = store.getState()

      state.user.auth.isAuthorized = currentState.user.auth.isAuthorized
      state.user.profile.error = currentState.user.profile.error
      state.user.profile.info = currentState.user.profile.info
      state.user.profile.favouriteAds = currentState.user.profile.favouriteAds
      state.user.profile.savedFilters = currentState.user.profile.savedFilters
      state.user.profile.favouriteProfiles = currentState.user.profile.favouriteProfiles
      state.user.profile.packageInactiveMessage = currentState.user.profile.packageInactiveMessage
      state.user.listSidebar = currentState.user.listSidebar

      state.common.transitions = currentState.common.transitions
      state.common.router = currentState.common.router
      state.common.is404 = !isPageExists
      state.common.userTypes = currentState.common.userTypes
      state.common.selectableUserTypes = currentState.common.selectableUserTypes
      state.common.isMobileDevice = currentState.common.isMobileDevice
      state.common.pageMetaTags = currentState.common.pageMetaTags
      state.common.pageMetaTagsSingle = currentState.common.pageMetaTagsSingle

      state.groups.elements = currentState.groups.elements

      state.categories.elements = currentState.categories.elements
      state.categories.withGroups = currentState.categories.withGroups
      state.categories.bySeo = currentState.categories.bySeo
      state.categories.parentCategories = currentState.categories.parentCategories

      if (isPageExists) {
        state.ads.list = currentState.ads.list
        state.ads.brands = currentState.ads.brands
        state.ads.pagination = currentState.ads.pagination
        state.ads.products = currentState.ads.products
        state.ads.equipment = currentState.ads.equipment
      }

      state.geoObjects.countries = currentState.geoObjects.countries
      state.geoObjects.regions = currentState.geoObjects.regions
      state.geoObjects.cities = currentState.geoObjects.cities

      state.ads.sms = currentState.ads.sms

      state.form = currentState.form

      state.cart = currentState.cart
      state.orders = currentState.orders
    }
    return state
  }

  renderPagination = () => {
    const { pagination, isInfiniteScrollEnabled } = this.props

    if (pagination.adsType === adsTypes.free) return <FreeAdsHomePagination />
    if ([adsTypes.all, adsTypes.paid].includes(pagination.adsType) && !isInfiniteScrollEnabled) return <AllAdsHomePagination />

    return null
  }

  render () {
    const {
      pagination,
      isPageExists,
      noAdsMessage,
      layoutStyle,
      isInfiniteScrollEnabled,
      packageInactiveMsg,
      setUserPackageInactiveMsg,
      isMobileDevice
    } = this.props

    return isPageExists
      ? (
        <Page className={layoutStyle === 'no-sidebar' ? 'main-content-extended' : ''}>
          {!isMobileDevice ? <AdsHeaderExtended formStyle='default' /> : <CategoryHorizontalMenuMobile />}
          <AdsList
            layoutStyle={layoutStyle}
            formStyle='default'
            adsType={pagination.adsType}
            enabledInfiniteScroll={isInfiniteScrollEnabled}
            noAdsMessage={noAdsMessage}
            showBreadCrumbs={false}
          />
          {this.renderPagination()}
          {isMobileDevice ? <PanelMobile /> : null}
          <Notification
            isActive={packageInactiveMsg}
            message={packageInactiveMsg}
            action={'Sakrij'}
            onClick={() => { setUserPackageInactiveMsg('') }}
          />
        </Page>
      ) : <Page404 />
  }
}

const mapStateToProps = (state) => ({
  pagination: getPaginationParams(state),
  packageInactiveMsg: getUserPackageInactiveMsg(state)
})

IndexPage.propTypes = {
  pagination: PropTypes.shape({
    adsType: PropTypes.oneOf([
      adsTypes.paid,
      adsTypes.all,
      adsTypes.free
    ]).isRequired
  }),
  isPageExists: PropTypes.bool.isRequired,
  noAdsMessage: PropTypes.string.isRequired,
  layoutStyle: PropTypes.string.isRequired,
  isInfiniteScrollEnabled: PropTypes.bool.isRequired,
  packageInactiveMsg: PropTypes.string.isRequired,
  setUserPackageInactiveMsg: PropTypes.func.isRequired
}

IndexPage.defaultProps = {
  isPageExists: false,
  layoutStyle: 'default'
}

export default connect(mapStateToProps, { setUserPackageInactiveMsg })(IndexPage)
